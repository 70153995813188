<template>
  <el-dialog
    class="editDialog"
    :append-to-body="true"
    width="600px"
    :visible.sync="showEditDialog"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="$emit('update:isShow', false)"
    :show-close="true">
    <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}字典</div>

    <div class="content" v-loading="loading">
      <el-form label-width="9em" :model="form" :rules="rules" ref="form">
        <el-form-item label="字典名称" prop="dictName">
          <el-input
            v-model="form.dictName"
            placeholder="请输入字典名称"
            @keyup.enter.native="submit"></el-input>
        </el-form-item>

        <el-form-item label="字典代码" prop="dictType">
          <el-input
            v-model="form.dictType"
            placeholder="请输入字典代码"
            @keyup.enter.native="submit"></el-input>
        </el-form-item>
        <el-form-item label="排序号（升序）" prop="sort">
          <el-input
            v-model="form.sort"
            type="number"
            class="numrule"
            placeholder="请输入字典排序号"
            @keyup.enter.native="submit"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div slot="footer" v-show="!loading">
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      loading: true,
      submitLoading: false,
      form: {},
      rules: {
        dictName: [{ required: true, message: '请输入字典名称', trigger: ['blur', 'change'] }],
        dictType: [{ required: true, message: '请输入字典代码', trigger: ['blur', 'change'] }],
      },
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        // this.$emit('update:loading', false);
        this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.form = newVal
        this.loading = false
      },
    },
  },
  created() {},
  methods: {
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true

          var key = this.form.id ? 'edit' : 'add'

          this.$api.dictClassify[key](this.form)
            .then(res => {
              this.submitLoading = false
              this.refresh(1)
              this.$message.success('操作成功！')
            })
            .catch(err => {
              this.submitLoading = false
              console.log(err)
            })
        } else {
          this.scrollView(obj)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
</style>
